.week{
  padding:2rem 0;
  border-bottom:1px solid $color-bottom-border;
  &-header{
    display: flex
  }
  &-remove{
    color:$color-red;
    flex:1;
    align-self: center;
    text-align: right;
  }
}

.add-week{
  padding:2rem 0;
  text-align: center;
}
