$color-blue: #007AFF;
$color-light-grey: #F7F7F7;
$color-white-grey: #f7f7f7ad;
$color-white: #fff;
$color-black: black;
$color-grey:#666;
$color-tab-hover:#cacaca40;
$color-red:red;
$color-bottom-border:#E5E5E5;
$color-green:#0DCE7C;
$color-orange:#FF6200;
$color-purple:#1C0DCE;
$color-dark-blue:#0D232E;
$color-light-red:#FF2D55;
$color-light-purple:#C400FF;
$color-yellow:#ebd00b;
