.month{
  padding:2.5rem 1.5rem;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  margin-bottom:2rem;
  border-radius: 10px;
  background-color: #fff;
  &-header{
    display: flex;
    &:hover{
      cursor: pointer;
    }
    &-icon{
      flex:1;
      align-self: center;
      text-align: right;
    }
  }
}


.month-no-back{
  padding:4.5rem 0;
  &-header{
    display: flex;
    &:hover{
      cursor: pointer;
    }
    &-icon{
      flex:1;
      align-self: center;
      text-align: right;
    }
  }
}
