.main-page-container {
  position: relative;
}

.section-container {
  margin-left: 230px;
  max-width: 1200px;
  min-width: 900px;
  overflow:'scroll';
  padding-bottom:10rem;
  background-color: white;
  &-padding{
    padding:0 1.5rem;
  }
}

.onboarding-container {
  padding:50px 20px;
  margin: auto;
  width: 50%;
}

.form-container {
  margin: auto;
  width: 95%;
  margin-top:20px;
}

.week-container {
  margin: auto;
  width: 95%;
  margin-top:20px;
}


.grid-three{
  display: grid;
  grid-template-columns:1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap:2rem;
}

.grid-four{
  display: grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap:2rem;
}
.grid-five{
  display: grid;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap:2rem;
}

.grid-six{
  display: grid;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap:2rem;
}

.grid-seven{
  display: grid;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap:2rem;
}

.grid-two{
  display: grid;
  grid-template-columns:1fr 1fr;
  column-gap: 2rem;
  row-gap:2rem;
}

.grid-two-thirds {
  display: grid;
  grid-template-columns:1fr 7fr 1fr;
  column-gap: 2rem;
  row-gap:2rem;
}

.form-row {
  display: flex;
}

.forgot-password-container{
  margin: auto;
  width: 50%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin-top:50px;
  box-shadow: 0 1px 2px rgba(0,0,0,.2)
}

.profile-container{
  margin:auto;
  width: 70%;
  margin-top:20px;
}

.drill-container{
  overflow: hidden;
  margin: auto;
  width: 60%;
  background-color: white;
  border-radius: 20px;
  margin-top:20px;
  box-shadow: 0 1px 2px rgba(0,0,0,.2)
}


.messages-container{

}
.messages-grid{
  display: grid;
  grid-template-columns:1fr 3fr;
  column-gap: 2rem;
  row-gap:2rem;
  margin:20px;
  position: fixed;
  max-width: 1200px;
  min-width: 900px;
  width:80%;
}



@media only screen and (max-width: 1024px) {
  .onboarding-container{
    width: 60%;
  }

}


@media only screen and (max-width: 600px) {
    .onboarding-container{
    width: 100%;
    background: white;
    padding:20px 10px
  }
}
