body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.7;
  background-color: white;
}

a {
  text-decoration: none;
  color:$color-purple;
  font-weight: 600;
  font-size: 1.4rem;
  &:hover{
    cursor:pointer
  }
}

.bold {
  font-weight: 600
}

.h1-black {
  color:$color-black;
  font-size: 3.4rem;
}

.h2-black {
  color:$color-black;
  font-size: 2.6rem;
}

.h3-black {
  color:$color-black;
  font-size: 1.6rem;
}

.h3-grey {
  color:$color-grey;
  font-size: 1.6rem;
}

.h3-blue-grey{
  color:#7b7e90;
  font-size: 1.6rem;
}

.h1-white {
  color:$color-white;
  font-size: 3.4rem;
}

.h2-white {
  color:$color-white;
  font-size: 2.6rem;
}

.h3-white {
  color:$color-white;
  font-size: 1.6rem;
}

.h3-green {
  color:$color-green;
  font-size: 1.6rem;
}

.body1-black {
  color:$color-black;
  font-size: 1.4rem;
}

.body1-grey {
  color:$color-grey;
  font-size: 1.4rem;
}

.body2-black {
  color:$color-black;
  font-size: 1.3rem;
}

.body2-grey {
  color:$color-grey;
  font-size: 1.3rem;
}

.h1-white {
  color:$color-white;
  font-size: 3.4rem;
}

.h2-white {
  color:$color-white;
  font-size: 2.6rem;
}
