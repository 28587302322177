.side-nav {
  height:100%;
  width:230px;
  position: fixed;
  z-index:5;
  overflow-x:hidden;
  top:0;
  left:0;
  background: white;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

.side-nav-header{
  padding:10px;
  text-align: center;
  border-bottom:1px solid $color-bottom-border;

}

.logo{
  width:70px;
  height:70px;
  border-radius:35px;
}

.tab {
  display:flex;
  border-radius: 30px;
  padding:5px 10px;
  width:80%;
  margin:2rem 1rem;
  &-active{
    background-color: $color-green;
  }
  &-icon{
    display: flex;
    color:$color-black;
    margin-right: 10px;
    align-self: center;
  }
}

.nav {
  width:100%;
  display: flex;
  padding:1.5rem 1.5rem;
  z-index: 100;
  background: white;
  // box-shadow: 0 1px 2px rgba(0,0,0,.2);
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  overflow:'scroll';
  position: sticky; top: 0;
  &-back{
    align-self: center;
    &:hover{
      cursor:pointer;
    }
  }
  &-btn{
    flex:1;
    text-align: right;
  }

}

.nav-onboarding{
  display: flex;
  width:100%;
  padding:1.5rem 0;
  margin-bottom:20px;
  z-index: 100;
  overflow:'scroll';
}

.tab-bar-line{
  display: flex;
  border-bottom: 2px solid #ededed;
}
.tab-bar{
  background: white;
  display: flex;
  &-tab{
    font-size: 1.6rem;
    font-weight: 600;
    color:$color-grey;
    width:100px;
    text-align: center;
    &-active{
      color:$color-purple;
      border-bottom:5px solid $color-purple;
    }
    &:hover{
      cursor: pointer;
    }
  }
}

.line-tab-bar{
  background: white;
  display: flex;
  &-tab{
    font-size: 1.4rem;
    font-weight: 600;
    color:$color-grey;
    width:100px;
    text-align: center;
    &-active{
      color:$color-black;
      border-bottom:2px solid $color-black;
    }
    &:hover{
      cursor: pointer;
    }
  }
}
