.checkbox {
  display: flex;
  width: 30rem;
  margin-bottom:1.5rem;
  &:hover{
    cursor: pointer;
  }
  &-input{
    margin-right:7px;
    height: 2rem;
    width: 2rem;
    align-self:center;
  }

}

.group{
    p{
      font-size:1.3rem;
      color:$color-red;
    }
}
select {
  border-right: 16px solid transparent
}

.search{
  background-color: #ECECEE;
  border:none;
  width:100%;
  border-radius: 30px;
  padding:8px;
  display: flex;
  &-icon{
    margin-right:1rem;
    align-self: center;
  }
}

.search-bar {
  border:none;
  resize: none;
  width:100%;
  background-color: #ECECEE;
  font:inherit;
  font-size: 1.8rem;
  &:focus {
    outline: none;
  }
}

.form-input{
  background-color: #ECECEE;;
  width:100%;
  border-radius: 30px;
  border:none;
  padding:1.3rem 1.3rem;
  resize: none;
  font:inherit;
  font-size: 1.6rem;
}

.form-small-input{
  background-color: white;
  border-radius: .3rem;
  border:1px solid #CACACA;
  padding:.3rem;
  resize: none;
  font:inherit;
  font-size: 1.3rem;
  font-weight: 600;

}

.form-error{
  border:1px solid $color-red
}

.input-tab{
  display: flex
}

.form-input-tab{
  border:1px solid #CACACA;
  padding:.3rem .8rem;
  margin: 0 3px;
  resize: none;
  font:inherit;
  border-radius: .3rem;
  font-size: 1.3rem;
  font-weight: 600;
  background-color: #C8C7C7
}

.group-light{
  margin-bottom: 1rem
}

.form-input-light{
  background-color: white;
  width:100%;
  border:1px solid #CACACA;
  border-radius: 3px;
  padding:1rem;
  resize: none;
  font:inherit;
  font-size: 1.3rem;
  p{
    font-size:1.3rem;
    color:$color-red;
  }
}

.form-input-logo{
  border:1px solid #dddd;
  border-radius:3px;
  &:hover{
    cursor: pointer;
  }
}

.form-input-video{
  border-radius:3px;
  &:hover{
    cursor: pointer;
  }
}

.group-item{
  display: flex;
  background-color: $color-light-grey;
  padding:1rem;
  margin:1rem;
  border-radius: .3rem;
}
