.threads{
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  overflow-y:scroll;
  &-header{
    padding:10px 15px;
    border-bottom: 1px solid #ddd;
  }
  &-row{
    display: flex;
    padding: 15px;
    border-left:4px solid transparent;
    border-bottom: 1px solid #ddd;
    &-selected{
      border-left:4px solid $color-purple;
    }
    &:hover{
      cursor:pointer;
      background-color: $color-light-grey;
    }
  }
}

.messages{
  background-color: white;
  border-radius:10px;
  height: 500px;
  overflow-y: scroll;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

.chat-list{
  padding:1.5rem;
  &-date{
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: $color-grey;
  }
}


.message{
  padding:12px;
  border-radius: 15px;
  font-size:1.5rem;
  font-weight: 500;
  max-width: 75%;
  display: inline-block;
  &-right{
    background-color: $color-green;
    color:white;
  }
  &-left{
    background-color: #e4e6eb;
    text-align: left;
    color:$color-black;
  }
  &-container{
    margin-bottom:30px;
  }
}

.message-input{
  padding:1.5rem;
  display: flex;
  background-color: white;
  margin-top:10px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  &-container{
    background-color: #f0f2f5;
    border:none;
    padding:10px;
    border-radius: 3px;
    width:100%;
    &:focus{
      outline: none;
    }
  }

}

.send{
  align-self: center;
}
.send:hover{
  cursor:pointer;
}

.thread-unread{
  display: inline-flex;
  flex:1;
  align-self: flex-end;
  font-weight: 600;
  font-size: 1.6rem;
  background-color: red;
  color:white;
  width:20px;
  height:20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.message-thumbnail{
  background-position: center;
  background-size: cover;
}
