.dashboard-header{
  padding:1.5rem 1.5rem;
  background: white;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  position: sticky; top: 0;
  display: grid;
  grid-template-columns:1fr 1fr;
  &-inner{
    display: grid;
    grid-template-columns:1fr 1fr 2fr;
  }
  &-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}


.new-dashboard-header{
  padding:0 1.5rem;
  padding-top:1.5rem;
  background: white;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  position: sticky; top: 0;
}
