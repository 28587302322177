*,
*::after,
*::before {
  margin:0;
  padding:0;
  box-sizing: inherit;
}

ul {
  list-style: none;
}


html {
  // this defines what 1rem is
  font-size: 62.5%
}

body{
  box-sizing: border-box;
}
