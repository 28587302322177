.modal{
  height:100%;
  width:100%;
  position: fixed;
  top:0;
  left:0;
  background-color: rgba($color-black,.6);
  z-index:9999;
  &-header{
    border-bottom:1px solid #EDEDED;
    padding:1.5rem;
  }
  &-content{
    padding:1.5rem;
    &-scrollable{
      height:55vh;
      overflow-y: auto;
    }

  }
  &-send{
    border-top:1px solid #EDEDED;
    padding:1rem;
  }
  &-footer{
    border-top:1px solid #EDEDED;
    padding:1.5rem;
    display: flex;
    justify-content: flex-end;
    &-btn{
      margin-left: 1rem;
    }
  }
  &-main{
    background-color:$color-white;
    border-radius: 15px;
    @include absCenter;
  }
  &-small{
    width:45rem;
  }
  &-medium{
    width:70rem;
  }
  &-large{
    width:100rem;
  }
  &-xlarge{
    width:100vw;
    height:100vh;
  }
  &-grey{
    background-color:#f0f2f5
  }

}
