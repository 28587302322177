.login-container{
  width:100%;
  height:100vh;
  background-image: url('../../../public/background1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns:1fr 1fr;
  &-left{
    display: flex;
    padding:0 6rem;
    background-color: rgba(0,0,0,.5);
  }
  &-right{
    display: flex;
    padding:0 6rem;
    background-color: rgba(255,255,255,.85)

  }

}
