.all-drills-header{
  padding:1.5rem 1.5rem;
  background: white;
  // box-shadow: 0 1px 2px rgba(0,0,0,.2);
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  position: sticky; top: 0
}

.drill-item{
  display: flex;
  margin-bottom: 2rem;
  &-circle{
    width:3rem;
    height:3rem;
    border-radius:1.5rem;
    background:#2b3749;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;
    color: white;
    align-self: center;
  }
}

.drills-tab-bar{
  display: flex;
  & li{
    width:100px;
    color:#777;
    font-size: 1.4rem;
    font-weight: 600;
    padding-bottom:3px;
    text-align: center;
    &:hover{
      cursor: pointer;
    }
  }
}

.drill-tab-active{
  border-bottom:3px solid $color-purple;
  color:$color-purple !important;
}
