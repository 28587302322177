textarea:focus, input:focus{
  outline: none;
}

.card {
  border-radius: 10px;
  overflow: hidden;
  background: white;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  &:hover{
    cursor: pointer;
  }
  &-active{
    border:3px solid $color-blue
  }
  &-bottom{
    padding:1.5rem 1rem;
    background-color: white;
  }
  &-add{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:1.5rem;
    background-color: #fafafa;
  }
  &-stats{
    min-height:10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:1rem;
  }
  &-leaderboards{
    padding:1rem;
  }
  &-add:hover{
    cursor:pointer;
  }
}

.video-card{
  background-color: white;
  color:$color-purple;
  border:2px dashed #cacaca;
  height:200px;
  width:200px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
}

.content-card{
  border:3px solid white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding:10px;
  &-active{
    border:5px solid $color-purple;
  }
  aspect-ratio: 1;
    &:hover{
      cursor: pointer;
    }

    &-circle{
      width:30px;
      height:30px;
      border-radius: 15px;
      border:3px solid white;
      justify-content: center;
      display: flex;
      align-items: center;
      &-active{
        background-color:$color-purple
      }
    }
}

.btn {
  border-radius: 30px;
  padding: 7px 1.5rem;
  font-size: 1.6rem;
  text-align: center;
  font-weight: 600;
  &-block{
    display: block;
  }
  &-small{
    padding:0px 1rem;
  }
  &-lrg{
    padding:10px 25px;
  }
}

.btn:hover{
  opacity:.8
}

.btn-disabled{
  color:$color-grey;
  background-color: #e4e1e1;
}

.btn-light-red{
  color:$color-white;
  background-color: $color-light-red;
}

.btn-border-light-red{
  color:$color-light-red;
  border: 1px solid $color-light-red;
}

.btn-border-purple{
  color:$color-purple;
  border: 1px solid $color-purple;
}

.btn-green {
  color:$color-white;
  background-color: $color-green;
}

.btn-purple {
  color:$color-white;
  background-color: $color-purple;
}

.btn-grey {
  color:$color-black;
  background-color: #e2e8f0;
}

.btn-red {
  color:$color-white;
  background-color: $color-red;
}

.btn-border {
  color:$color-black;
  background-color: $color-white;
  border:1px solid #DDDD
}

.btn-border-green {
  color:$color-blue;
  border:1px solid $color-blue;
}

.btn-border-green {
  color:$color-green;
  border:1px solid $color-green;
}

.tag{
  border-radius: 25px;
  padding:4px 8px;
  margin-right:4px;
  font-size: 1.1rem;
  font-weight: 600;
  &-lrg{
    padding:5px 15px;
    font-size: 1.5rem;
  }
  &-md{
    padding:5px 15px;
    font-size: 1.3rem;
  }
}
.tag-grey{
  color:$color-black;
  background-color: #e2e8f0
}
.tag-red{
  color:$color-white;
  background-color: $color-red
}
.tag-green{
  color:$color-white;
  background-color: $color-green
}
.tag-purple{
  color:$color-white;
  background-color: $color-purple
}
.tag-border{
  color:$color-black;
  border:1px solid #dddd;
}

.tag-blue{
  color:$color-white;
  background-color: $color-blue
}
.tag-orange{
  color:$color-white;
  background-color: $color-orange
}
.tag-yellow{
  color:$color-white;
  background-color: $color-yellow
}
.tag-light-purple{
  color:$color-white;
  background-color: $color-light-purple
}
.tag-light-red{
  color:$color-white;
  background-color: $color-light-red
}


.clear-fix{
  display: flex;
  &-right{
    align-self: center;
    flex:1;
    text-align: right;
  }
}

.thumbnail{
  width:100%;
  height:6rem;
  border-top-right-radius:2px;
  border-top-left-radius: 2px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.overlay{
  width:100%;
  height:6rem;
  background-color: rgba(0,0,0,.25);
}

.remove-icon{
  text-align: right;
  padding:5px;
}

.play-icon{
  display: flex;
  justify-content: center;
  align-items: center;
}

.instructions{
  display: flex;
  margin-bottom: 1rem;
  &-text{
    font-size: 1.6rem;
    align-self: center;
  }
  &-circle{
    width:3rem;
    height:3rem;
    margin-right: 10px;
    border-radius: 1.5rem;
    background-color: $color-light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pointer:hover{
  cursor: pointer;
}

.load-more{
  text-align: center;
  padding:2rem 1rem;
}

.center{
  text-align: center;
}

.align-center{
  align-self: center;
  flex:1;
}


.table{
  background-color: $color-white;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  width:100%;
  margin:2rem 0;
  &-header{
    background-color: #e2e8f0;
  }
  &-cell{
    font-weight: 500;
    font-size:1.6rem;
    color:#212121;
  }
}
td{
  border-bottom: 1px solid #dddd;
  &:hover{
    cursor:pointer;
  }
}
th,td{
  padding:1.5rem;
  font-size:1.6rem;
  text-align: left;
}



@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid $color-green; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container{
  display: flex;
  justify-content: center;
}

.no-items{
  border-radius: 10px;
  padding:3rem 1.5rem;
  width:100%;
  background: white;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

.no-items-container{
  margin-top:20px;
  margin-bottom:20px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.copy:hover{
  opacity:.5
}

.label-header{
  padding:15px;
  background-color: #e2e8f0;
}
