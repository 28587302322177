.filters{
  display: flex;
}

.filter{
  background-color: #fff;
  box-shadow:  0 2px 12px 0 #D4D4D4;
  max-height: 40rem;
  overflow-y: scroll;
  border-radius: 13px;
  transition: all .3s cubic-bezier(.18,.89,.32,1.28);
  z-index: 2;
  padding:1.3rem;
  padding-bottom: 0;
  margin-top:10px;
  position: absolute;
}

.dropdown{
  margin-right:5px;
}
