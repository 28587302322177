.u-margin-bottom-5{
  margin-bottom:5px;
}


.u-margin-bottom-10{
  margin-bottom: 1rem;
}

.u-margin-bottom-15{
  margin-bottom: 1.5rem;
}

.u-margin-bottom-20{
  margin-bottom: 2rem;
}

.u-margin-bottom-40{
  margin-bottom: 4rem;
}


.u-margin-bottom-80{
  margin-bottom: 8rem;
}

.u-margin-top-10{
  margin-top: 1rem;
}

.u-margin-top-15{
  margin-top: 1.5rem;
}

.u-margin-top-20{
  margin-top: 2rem;
}

.u-margin-top-40{
  margin-top: 4rem;
}

.u-margin-right-5{
  margin-right:5px;
}


.u-margin-right-10{
  margin-right: 1rem;
}

.u-margin-right-15{
  margin-right: 1.5rem;
}

.u-margin-right-20{
  margin-right: 2rem;
}

.u-center {
  align-self: center;
}

.u-padding-20 {
  padding:20px
}
